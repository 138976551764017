import React from "react"

const SvgComponent: React.FC = props => (
  <svg width={21} height={20} viewBox="0 0 21 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.917.06C6.982.02 8.356 0 10.039 0c1.683 0 3.057.02 4.122.06.908.039 1.716.196 2.426.473a5.033 5.033 0 011.776 1.144c.5.5.88 1.09 1.143 1.775.277.71.434 1.518.474 2.426.04 1.065.06 2.439.06 4.122 0 1.683-.02 3.057-.06 4.122-.04.908-.197 1.716-.474 2.426a5.033 5.033 0 01-1.143 1.776c-.5.5-1.092.88-1.776 1.143-.71.277-1.518.434-2.426.474-1.065.04-2.439.059-4.122.059-1.683 0-3.057-.02-4.122-.06-.908-.039-1.716-.196-2.426-.473a5.034 5.034 0 01-1.775-1.143c-.5-.5-.881-1.092-1.144-1.776-.277-.71-.434-1.518-.474-2.426C.058 13.057.04 11.683.04 10c0-1.683.02-3.057.06-4.122.039-.908.196-1.716.473-2.426a5.033 5.033 0 011.144-1.775c.5-.5 1.091-.881 1.775-1.144C4.2.256 5.009.099 5.917.059zm8.403 1.3c-1.104-.039-2.53-.058-4.28-.058-1.748 0-3.175.02-4.28.059a6.332 6.332 0 00-1.972.375 3.601 3.601 0 00-2.012 2.012 6.332 6.332 0 00-.375 1.972A124.74 124.74 0 001.342 10c0 1.749.02 3.175.06 4.28.026.71.15 1.368.374 1.972a3.601 3.601 0 002.012 2.012 6.333 6.333 0 001.972.375c1.105.04 2.532.06 4.28.06 1.75 0 3.176-.02 4.28-.06a6.333 6.333 0 001.973-.375 3.6 3.6 0 002.012-2.012 6.33 6.33 0 00.375-1.972c.039-1.104.059-2.531.059-4.28s-.02-3.176-.06-4.28a6.33 6.33 0 00-.374-1.972 3.6 3.6 0 00-2.012-2.012 6.332 6.332 0 00-1.973-.375zM7.2 12.84a3.907 3.907 0 002.84 1.164c1.104 0 2.048-.391 2.83-1.174.783-.782 1.174-1.725 1.174-2.83 0-1.104-.391-2.048-1.174-2.83-.782-.783-1.726-1.174-2.83-1.174-1.105 0-2.048.391-2.83 1.174-.783.782-1.174 1.726-1.174 2.83 0 1.105.388 2.051 1.164 2.84zm-.79-6.469c1-1 2.21-1.499 3.63-1.499s2.63.5 3.63 1.5c.999.998 1.498 2.208 1.498 3.628s-.5 2.63-1.499 3.63c-.999.999-2.209 1.498-3.629 1.498s-2.63-.5-3.63-1.498c-.998-1-1.498-2.21-1.498-3.63s.5-2.63 1.499-3.629zm9.803-2.544c.25.237.375.52.375.848 0 .329-.119.608-.355.838-.237.23-.526.345-.868.345-.329 0-.608-.115-.838-.345-.23-.23-.346-.51-.346-.838 0-.342.116-.631.346-.868.23-.237.51-.355.838-.355.329 0 .611.125.848.375z"
      // as this icon is simulating a stroke, set fill to stroke variable
      fill="var(--social-icon-stroke)"
    />
  </svg>
)

export default SvgComponent
