import React from "react"

const MenuIcon: React.FC = props => (
  <svg width={16} height={17} viewBox="0 0 16 17" fill="none" {...props}>
    <rect width={16} height={17} fill="transparent" />
    <path d="M0 11H8H16M0 5H16H0Z" stroke="#54301A" strokeWidth={2} />
  </svg>
)

export default MenuIcon
