import { css } from "@emotion/react"

export const baseFontSize = "16px"
export const baseLineHeight = 2.0
export const scaleRatio = 4
export const headingsLineHeight = 1.4
export const bodyTypefaces = [
  "Roboto Mono",
  "SFMono-Regular",
  "Menlo",
  "Monaco",
  "Consolas",
  "Liberation Mono",
  "Courier New",
  "monospace",
]

export const headingsTypefaces = [
  "Maitree",
  "Constantia",
  "Lucida Bright",
  "Lucidabright",
  "Lucida Serif",
  "Lucida",
  "DejaVu Serif",
  "Bitstream Vera Serif",
  "Liberation Serif",
  "Georgia",
  "serif",
]

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1760,
}

export const mediaQueries = {
  min576: `@media screen and (min-width: ${breakpoints.sm}px)`,
  min768: `@media screen and (min-width: ${breakpoints.md}px)`,
  min992: `@media screen and (min-width: ${breakpoints.lg}px)`,
  min1200: `@media screen and (min-width: ${breakpoints.xl}px)`,
  min1760: `@media screen and (min-width: ${breakpoints.xxl}px)`,
  max576: `@media screen and (max-width: ${breakpoints.sm - 0.02}px)`,
  max768: `@media screen and (max-width: ${breakpoints.md - 0.02}px)`,
  max992: `@media screen and (max-width: ${breakpoints.lg - 0.02}px)`,
  max1200: `@media screen and (max-width: ${breakpoints.xl - 0.02}px)`,
  max1760: `@media screen and (max-width: ${breakpoints.xxl - 0.02}px)`,
  range576to768: `@media screen and (min-width: ${
    breakpoints.sm
  }px) and (max-width: ${breakpoints.md - 0.02}px)`,
  range768to992: `@media screen and (min-width: ${
    breakpoints.md
  }px) and (max-width: ${breakpoints.lg - 0.02}px)`,
  range992to1200: `@media screen and (min-width: ${
    breakpoints.lg
  }px) and (max-width: ${breakpoints.xl - 0.02}px)`,
}

export default css`
  :root {
    /* Colors */
    --primary: #54301a;
    --secondary: #306914;
    --secondary-light: #89c16d;
    --merino: #f7f2e9;

    --body-bg: var(--merino);

    /* Typography */
    --base-font-size: ${baseFontSize};
    --base-line-height: ${baseLineHeight};

    --body-font-family: ${bodyTypefaces.join(",")};
    --body-color: var(--primary);

    --headings-font-family: ${headingsTypefaces.join(",")};
    --headings-color: var(--secondary);
    --headings-weight: 400;
    --headings-line-height: ${headingsLineHeight};
  }
`
