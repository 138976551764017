import React from "react"

const Arrow: React.FC = props => {
  return (
    <svg width={15} height={23} viewBox="0 0 15 23" fill="none" {...props}>
      <path d="M7 .682V21.819h1.3V0H7v.682z" fill="#F7F2E9" />
      <path
        d="M7.5 21.5l-6-6M13.5 15.5l-6 6"
        stroke="#F7F2E9"
        strokeLinecap="square"
      />
    </svg>
  )
}

export default Arrow
