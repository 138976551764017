import React from "react"

const FacebookIcon: React.FC = props => (
  <svg width={12} height={21} viewBox="0 0 12 21" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.643 1.47v2.946H8.89c-.64 0-1.072.134-1.295.402-.223.268-.335.67-.335 1.205v2.11h3.27l-.435 3.303H7.261v8.471H3.846v-8.47H1V8.132h2.846V5.7c0-1.384.387-2.457 1.16-3.22.775-.763 1.805-1.144 3.092-1.144 1.094 0 1.942.045 2.545.134z"
      fill="var(--social-icon-fill)"
      stroke="var(--social-icon-stroke)"
    />
  </svg>
)

export default FacebookIcon
