import { Link } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import styled from "@emotion/styled"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { mediaQueries } from "../../styles"
import { AmexIcon, MasterCardIcon, VisaIcon } from "../../svgs/CreditCards"
import azulLogo from "../../images/azul-logo.png"

const Footer = () => {
  const { t } = useTranslation("", { keyPrefix: "footer" })

  return (
    <StyledFooter>
      <Container>
        <Row>
          <Col sm={12}>
            <Flex>
              <span className="order-5 order-sm-0">
                Alimentos Fortuna &copy; {new Date().getFullYear()}
              </span>

              <StyledLink
                to="/terminos-y-condiciones"
                className="order-2 order-sm-1"
              >
                {t("t&c")}
              </StyledLink>

              <StyledLink
                to="/politicas-de-seguridad"
                className="order-3 order-sm-2"
              >
                {t("secPolicies")}
              </StyledLink>

              <StyledAnchor href="#" className="order-4 order-sm-3">
                {t("byline")}
              </StyledAnchor>

              {/* <button className="order-1 order-sm-4">ES/EN</button> */}

              <CreditCardsRow className="order-0 order-sm-5">
                <img src={azulLogo} alt="AZUL" />
                <VisaIcon />
                <MasterCardIcon />
                <AmexIcon />
              </CreditCardsRow>
            </Flex>
          </Col>
        </Row>
      </Container>
    </StyledFooter>
  )
}

export default Footer

const StyledFooter = styled.footer`
  padding: 1.5rem 0;
  background-color: var(--primary);
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: #fff;

  ${mediaQueries.min992} {
    padding: 2.25rem 0;
    font-size: 14px;
  }
`

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  ${mediaQueries.max576} {
    flex-direction: column;
    text-align: center;
    gap: 0.5rem;
  }

  ${mediaQueries.range576to768} {
    & > * {
      flex: 0 0 50%;

      /* align even items to the right */
      &:nth-child(2n) {
        text-align: right;
      }

      /* add margin top to 2nd+ rows */
      &:nth-child(n + 3) {
        margin-top: 1rem;
      }

      /* push inexact items of a 2nd+ row to the right */
      &:nth-child(2n + 3) {
        margin-left: auto;
      }
    }
  }

  ${mediaQueries.range768to992} {
    & > * {
      flex: 0 0 calc(100% / 3);

      /* add margin top to 2nd+ rows */
      &:nth-child(n + 4) {
        margin-top: 1rem;
      }

      /* push inexact items of a 2nd+ row to the right */
      &:nth-child(4n + 4) {
        margin-left: auto;
      }
    }
  }

  ${mediaQueries.range992to1200} {
    & > * {
      flex: 0 0 calc(100% / 3);

      &:nth-child(2n) {
        text-align: center;
      }

      &:nth-child(3n + 3) {
        text-align: right;
      }

      /* add margin top to 2nd+ rows */
      &:nth-child(n + 4) {
        margin-top: 1rem;
      }

      /* push inexact items of a 2nd+ row to the right */
      &:nth-child(4n + 4) {
        margin-left: auto;
      }
    }
  }
`

const StyledAnchor = styled.a`
  color: white;
  text-decoration: none;
`

const StyledLink = StyledAnchor.withComponent(Link)

const CreditCardsRow = styled.div`
  display: flex;
  justify-content: flex-end;

  & > * {
    flex: none;
    height: 28px;
    width: auto;

    &:not(:last-child) {
      margin-right: 0.25rem;
    }
  }

  @supports (gap: 0.25rem) {
    gap: 0.25rem;

    & > * {
      margin-right: 0;
    }
  }
`
