import { useState } from "react"
import { useI18next, Link } from "gatsby-plugin-react-i18next"
import styled from "@emotion/styled"
import { mediaQueries } from "../../styles"
import { GlobeIcon } from "../../svgs/GlobeIcon"

export function LangSwitcher(): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const { languages, originalPath, language } = useI18next()

  const handleClick = () => {
    setIsOpen(prevState => !prevState)
  }

  return (
    <Wrapper>
      <Switcher onClick={handleClick}>
        <GlobeIcon />
      </Switcher>
      {languages.map((lng, index, array) => {
        const isActive = language === lng
        const position = index + 1
        const zIndex = array.length - position
        return (
          <LangLink
            to={originalPath}
            language={lng}
            key={lng}
            isActive={isActive}
            position={position}
            zIndex={zIndex}
            isOpen={isOpen}
          >
            <Filler isActive={isActive} />
            {lng}
          </LangLink>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 2rem;

  ${mediaQueries.max576} {
    left: 0.75rem;
  }
`

const Switcher = styled.div`
  z-index: 3;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background: var(--primary);
  padding: 0.25rem;
  border: 2px solid var(--primary);
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  cursor: pointer;
`

const LangLink = styled(Link)<{
  position: number
  zIndex: number
  isOpen: boolean
  isActive: boolean
}>`
  z-index: ${({ zIndex }) => zIndex};
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  /* background: var(--merino); */
  background: ${({ isActive }) => (isActive ? "var(--primary)" : "var(--merino)")};
  border: 2px solid var(--primary);
  border-top: none;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  transform: translateY(
    ${({ isOpen, position }) => (isOpen ? `${position * 100}%` : 0)}
  );
  transition: 0.175s transform ease-in;
  cursor: pointer;
  /* color: var(--primary); */
  color: ${({ isActive }) => (isActive ? "var(--merino)" : "var(--primary)")};
  font-size: 0.75rem;
  font-weight: ${({ isActive }) => (isActive ? "bold" : "")};
  text-transform: uppercase;
  text-decoration: none;
`

const Filler = styled.div<{
  isActive: boolean
}>`
  position: absolute;
  top: calc(-1rem + 1.5px);
  width: 2rem;
  height: 1rem;
  background-color: ${({ isActive }) =>
    isActive ? "var(--primary)" : "transparent"};
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 2px solid var(--primary);
  border-right: 2px solid var(--primary);
`
