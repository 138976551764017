import styled from "@emotion/styled"

import NukaCarousel from "nuka-carousel"
import BsContainer from "react-bootstrap/Container"

import CSocialBar from "../SocialBar"

import { headingsFont, mediaQueries } from "../../styles"
export const HeroWrapper = styled.div`
  position: relative;
  height: calc(100vh - 106px);

  ${mediaQueries.max576} {
    padding-top: 5rem;
  }
`

export const Carousel = styled(NukaCarousel)`
  &:focus {
    outline: none;
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: calc(100vh - 106px);
  }
`

export const Overlay = styled.div`
  position: absolute;
  z-index: -999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.35;
`

export const Container = styled(BsContainer)`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${mediaQueries.max576} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const TopContent = styled.div`
  margin: auto 0;
  color: var(--merino);

  h1,
  h4 {
    color: var(--merino);
  }

  h1 {
    letter-spacing: 2px;
    font-weight: bold;
    text-transform: uppercase;
    overflow-wrap: break-word;
    word-break: break-word;

    ${mediaQueries.max576} {
      font-size: 44px;
      line-height: 1;
      letter-spacing: 1.2px;
    }
  }

  h4 {
    margin-bottom: 4.5rem;

    ${mediaQueries.max576} {
      margin-bottom: 4rem;
      font-size: 1.125rem;
    }
  }
`

export const BottomContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 2rem;
`

export const ScrollTag = styled.a`
  ${headingsFont}
  grid-column: 2 / span 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--merino);
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1.5px;

  span {
    margin-bottom: 0.5rem;
  }

  svg {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.8));
  }

  ${mediaQueries.max576} {
    display: none;
  }
`

export const SocialBar = styled(CSocialBar)`
  grid-column: 3 / span 1;
  --social-icon-fill: var(--merino);
  --social-icon-stroke: var(--merino);
  justify-content: flex-end;

  svg {
    filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.8));
  }

  ${mediaQueries.max576} {
    display: none;
  }
`
