import styled from "@emotion/styled"
import { Link } from "gatsby"

import LogoSvg from "../../svgs/Logo"
import MenuIconSvg from "../../svgs/MenuIcon"

import { headingsFont, mediaQueries } from "../../styles"

export const StyledHeader = styled.header`
  z-index: 10;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1.5rem 2rem;
  background-color: var(--merino);
`

export const HeaderLogo = styled(Link)`
  display: block;
  margin: 0 auto;
  line-height: 1;
`

export const Logo = styled(LogoSvg)`
  height: 2.5rem;

  path {
    fill: var(--primary);
  }

  ${mediaQueries.min768} {
    height: 3.5rem;
  }
`

// convert to a styled component for targeting in another styled component
export const MenuIcon = styled(MenuIconSvg)``

export const MenuButton = styled.button`
  position: absolute;
  top: 50%;
  left: 20px;
  padding: 0;
  background: transparent;
  border: 0;
  font-family: "Maitree";
  line-height: 1;
  text-transform: uppercase;
  cursor: pointer;
  transform: translateY(-50%);
`

export const NavLink = styled(Link)`
  ${headingsFont}
  padding: 0.25rem 0.5rem;
  color: var(--primary);
  font-size: 1.125rem;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  margin: 0 1.5rem;

  ${mediaQueries.max576} {
    display: none;
  }
`

export const SnipcartIcons = styled.div`
  display: flex;
  align-items: center;

  ${mediaQueries.max576} {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }

  & > * {
    cursor: pointer;
  }

  & > *:not(:last-of-type) {
    margin-right: 1.25rem;
  }
`
