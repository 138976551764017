import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import {
  Carousel,
  HeroWrapper,
  Container,
  ScrollTag,
  SocialBar,
  BottomContent,
  TopContent,
  Overlay,
} from "./styles"

import MadeInDR from "./MadeInDR"

import Arrow from "../../svgs/Arrow"

import { marked } from "marked"
import insane from "insane"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { HeroData } from "../../types/pages/IndexPage"

interface Props {
  heroData: HeroData
}

const Hero: React.FC<Props> = ({ heroData }) => {
  const { t } = useTranslation()

  return (
    <HeroWrapper>
      <Carousel
        slidesToShow={1}
        withoutControls={true}
        autoplay={true}
        autoplayInterval={10000}
        wrapAround={true}
        style={{
          position: `absolute`,
          top: 0,
          left: 0,
          width: `100%`,
          height: `100%`,
          zIndex: -9999,
        }}
      >
        {heroData.heroPhotos.map((heroImage, idx) => (
          <GatsbyImage
            image={heroImage.gatsbyImageData}
            alt={`Hero image ${idx + 1}`}
            key={heroImage.originalId}
          />
        ))}
      </Carousel>
      <Overlay />
      <Container>
        <TopContent>
          <h1
            dangerouslySetInnerHTML={{
              __html: marked.parseInline(insane(heroData.title), {
                breaks: true,
              }),
            }}
          />
          <h4
            dangerouslySetInnerHTML={{
              __html: marked.parseInline(insane(heroData.subtitle), {
                breaks: true,
              }),
            }}
          />

          <MadeInDR />
        </TopContent>

        <BottomContent>
          <ScrollTag href="#top">
            <span>{t("hero.seeMore")}</span>
            <Arrow />
          </ScrollTag>
          <SocialBar />
        </BottomContent>
      </Container>
    </HeroWrapper>
  )
}

export default Hero
