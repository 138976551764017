import React, { useState, useMemo } from "react"
// typing from custom.d.ts
// import { SnipcartContext } from "gatsby-plugin-snipcart-advanced/context"

import {
  StyledHeader,
  NavLink,
  MenuIcon,
  HeaderLogo,
  MenuButton,
  Logo,
} from "./styles"
import Menu from "./Menu"
import useWindowSize from "../../hooks/useWindowSize"
import { breakpoints } from "../../styles"

import { useTranslation } from "gatsby-plugin-react-i18next"
import { LangSwitcher } from "./LangSwitcher"

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { width } = useWindowSize()
  const { t } = useTranslation("", { keyPrefix: "header" })

  const isMobile = useMemo(() => (width ? width <= breakpoints.md : false), [width])

  const handleMenuClick = () => {
    setIsMenuOpen(prevState => !prevState)
  }

  return (
    <React.Fragment>
      <Menu isMenuOpen={isMenuOpen} handleMenuClick={handleMenuClick} />

      <StyledHeader>
        <LangSwitcher />

        {!isMobile ? (
          <React.Fragment>
            <NavLink to={t("navLinks.products.link")}>
              {t("navLinks.products.label")}
            </NavLink>
            <NavLink to={t("navLinks.recipes.link")}>
              {t("navLinks.recipes.label")}
            </NavLink>
          </React.Fragment>
        ) : (
          <MenuButton onClick={handleMenuClick}>
            <MenuIcon />
          </MenuButton>
        )}
        <HeaderLogo to="/">
          <Logo noOutline />
        </HeaderLogo>
        {!isMobile ? (
          <React.Fragment>
            <NavLink to={t("navLinks.cassava.link")}>
              {t("navLinks.cassava.label")}
            </NavLink>
            <NavLink to={t("navLinks.contactUs.link")}>
              {t("navLinks.contactUs.label")}
            </NavLink>
          </React.Fragment>
        ) : null}
        {/* <SnipcartIcons>
          <SnipcartCustomerIcon />
          <CartCount count={cartQuantity} />
        </SnipcartIcons> */}
      </StyledHeader>
    </React.Fragment>
  )
}
export default Header

// function SnipcartCustomerIcon(): JSX.Element {
//   return (
//     <div
//       style={{
//         padding: 8,
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       <UserIcon className="snipcart-customer-signin" />
//     </div>
//   )
// }
