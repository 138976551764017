export function GlobeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18.5 18.5"
      width={20}
      height={20}
    >
      <path
        d="M9.25 1.5A7.75 7.75 0 0 1 17 9.25h1.5C18.5 4.14 14.36 0 9.25 0v1.5zM1.5 9.25A7.75 7.75 0 0 1 9.25 1.5V0C4.14 0 0 4.14 0 9.25h1.5zM9.25 17A7.75 7.75 0 0 1 1.5 9.25H0c0 5.11 4.14 9.25 9.25 9.25V17zM17 9.25A7.75 7.75 0 0 1 9.25 17v1.5c5.11 0 9.25-4.14 9.25-9.25H17z"
        fill="#f7f2e9"
      />
      <path
        d="M9.25 1.5c.94 0 1.93.68 2.74 2.11.79 1.41 1.31 3.4 1.31 5.64h1.5c0-2.45-.56-4.71-1.5-6.38C12.37 1.22 10.97 0 9.25 0v1.5zM5.2 9.25c0-2.24.51-4.24 1.31-5.64.81-1.43 1.81-2.11 2.74-2.11V0C7.54 0 6.13 1.22 5.2 2.87 4.26 4.54 3.7 6.8 3.7 9.25h1.5zM9.25 17c-.94 0-1.93-.68-2.74-2.11-.79-1.41-1.31-3.4-1.31-5.64H3.7c0 2.45.56 4.71 1.5 6.38.93 1.65 2.33 2.87 4.05 2.87V17zm4.05-7.75c0 2.24-.51 4.24-1.31 5.64-.8 1.43-1.8 2.11-2.74 2.11v1.5c1.72 0 3.12-1.22 4.05-2.87.94-1.67 1.5-3.93 1.5-6.38h-1.5z"
        fill="#f7f2e9"
      />
      <path
        d="M9.25 11.7c-2.82 0-5.5.83-7.17 2.16l.94 1.17c1.33-1.07 3.65-1.84 6.23-1.84V11.7zm7.15 2.15c-1.75-1.31-4.31-2.15-7.15-2.15v1.5c2.56 0 4.8.76 6.25 1.85l.9-1.2zM9.25 6.8c2.84 0 5.4-.84 7.15-2.15l-.9-1.2C14.05 4.54 11.81 5.3 9.25 5.3v1.5zM2.1 4.65C3.85 5.96 6.41 6.8 9.25 6.8V5.3c-2.56 0-4.8-.76-6.25-1.85l-.9 1.2zM.75 8.5h17V10h-17z"
        fill="#f7f2e9"
      />
      <path d="M8.5.75H10v17H8.5z" fill="#f7f2e9" />
    </svg>
  )
}
