import React from "react"
import { css } from "@emotion/react"

type ButtonProps = {
  variant:
    | "primary"
    | "primary-filled"
    | "secondary"
    | "secondary-outline"
    | "outline-white"
}

const Button: React.FC<
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = props => (
  <button
    css={[
      baseButtonStyles,
      props.variant === "primary" && primaryButtonStyles,
      props.variant === "primary-filled" && primaryFilledButtonStyles,
      props.variant === "secondary" && secondaryButtonStyles,
      props.variant === "secondary-outline" && secondaryOutlineButtonStyles,
      props.variant === "outline-white" && outlineWhiteStyles,
    ]}
    {...props}
  />
)
export default Button

export const baseButtonStyles = css`
  display: block;
  min-width: 11.25rem;
  padding: 1.25rem 1.25rem;
  border: 1px solid;
  border-radius: 0;
  margin: 0;
  color: inherit;
  text-decoration: none;
  background-color: unset;
  font-size: 0.875rem;
  font-family: inherit;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  overflow: visible;
  transform: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out, transform 0.15s ease-in-out;

  &:focus {
    outline: thin dotted;
    outline-offset: 0.15em;
  }

  &:hover {
    border-color: var(--primary);
    background-color: var(--primary);
    color: #fff;
  }
`

const primaryButtonStyles = css`
  border-color: var(--primary);
  color: var(--primary);
`

const primaryFilledButtonStyles = css`
  ${primaryButtonStyles}
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--merino);
`

const secondaryButtonStyles = css`
  border-color: var(--secondary);
  background-color: var(--secondary);
  color: #fff;
`

const secondaryOutlineButtonStyles = css`
  border-color: var(--secondary);
  color: var(--secondary);
`

const outlineWhiteStyles = css`
  background-color: transparent;
  color: #ffffff;
  border-color: #ffffff;

  &:hover {
    background-color: #ffffff;
    border-color: #ffffff;
    color: var(--primary);
  }
`
