import { css } from '@emotion/react';
import { mediaQueries } from "../variables";

export const benefitIcon = css`
  width: 100%;
  max-width: 9.5rem;

  ${mediaQueries.max576} {
    margin-top: -8px;
  }
`
