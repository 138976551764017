import { css } from "@emotion/react"
import styled from "@emotion/styled"

import FacebookIcon from "../../svgs/FacebookIcon"
import InstagramIcon from "../../svgs/InstagramIcon"

import useSiteMetadata from "../../queries/useSiteMetadata"

const Bar = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-right: 0.5rem;
  }
`

const socialIcon = css`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  line-height: 1;
`

type Props = {
  withSpan?: boolean
  className?: string
}

const SocialBar = ({ withSpan, ...props }: Props) => {
  const { socialLinks } = useSiteMetadata()
  return (
    <Bar {...props}>
      {withSpan && <span>Síguenos</span>}
      <a
        href={socialLinks.facebook}
        target="_blank"
        rel="noopener noreferrer"
        css={socialIcon}
      >
        <FacebookIcon />
      </a>
      <a
        href={socialLinks.instagram}
        target="_blank"
        rel="noopener noreferrer"
        css={socialIcon}
      >
        <InstagramIcon />
      </a>
    </Bar>
  )
}

export default SocialBar
