import { css } from '@emotion/react'

export const snipcartStyles = css`
  .snip-layout {
    font-family: var(--body-font-family) !important;

    .snip-header {
      background-color: var(--primary) !important;
      font-family: var(--headings-font-family);

      &__title {
        color: var(--merino) !important;
      }

      &__total {
        background-color: var(--merino) !important;
        color: var(--primary) !important;

        &-label {
          color: #333 !important;
        }
      }
    }

    .snip-footer {
      background-color: var(--primary) !important;
      color: var(--merino) !important;

      &__copyright,
      &__highlight {
        color: #3d2312 !important;
      }
    }
  }
`
