import React from "react"

function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={38} height={38} fill="none" {...props}>
      <path fill="#89C16D" d="M27.844 8.842l1.13 1.129-18.82 18.813-1.13-1.13z" />
      <path fill="#89C16D" d="M28.973 27.655l-1.13 1.13L9.024 9.97l1.13-1.129z" />
    </svg>
  )
}

export default CloseIcon
