import { css } from "@emotion/react"
import { mediaQueries } from "../variables"

/* CSS */
export const recipesGrid = css`
  display: grid;
  grid-gap: 0;

  ${mediaQueries.max576} {
    grid-auto-flow: column;
    align-items: stretch;
    padding: 0 34px;
    margin: 0 -34px;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-padding: 0 1rem;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    & > * {
      scroll-snap-align: center;
    }
  }

  ${mediaQueries.min992} {
    align-items: stretch;
    grid-template-columns: repeat(3, minmax(calc(100% / 3), 1fr));
    overflow-x: initial;
  }

  .mobile-spacer {
    width: 1px;
  }
`
