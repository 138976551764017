/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Global, css } from "@emotion/react"

import Hero from "../Hero"
import Header from "../Header"
import Footer from "../Footer"

import "./bootstrap.scss"
import { snipcartStyles } from "./snipcart"
import variables from "../../styles/variables"
import { HeroData } from "../../types/pages/IndexPage"

type Props = {
  hasHero?: boolean
  heroData?: HeroData
}

const Layout: React.FC<Props> = ({ hasHero, heroData, children }) => {
  return (
    <React.Fragment>
      <Global
        styles={css`
          ${variables}
          body {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            background-color: var(--merino);
          }
          ${snipcartStyles}
        `}
      ></Global>
      <Header />
      {hasHero && heroData && <Hero heroData={heroData} />}
      <div id="top"></div>
      <main>{children}</main>
      <Footer />
    </React.Fragment>
  )
}

export default Layout
