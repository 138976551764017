import { useStaticQuery, graphql } from "gatsby"
import { SiteMetadata } from "../types/SiteMetadata"

const useSiteMetadata = (): SiteMetadata => {
  const { site }: { site: { siteMetadata: SiteMetadata } } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
          lang
          homePage {
            hasHero
          }
          socialLinks {
            facebook
            instagram
          }
        }
      }
    }
  `)

  return site.siteMetadata
}

export default useSiteMetadata
