import React from "react"
import styled from "@emotion/styled"
import { headingsFont } from "../../styles"
import CloseIcon from "../../svgs/CloseIcon"
import Button from "../Button"
import { useTranslation } from "gatsby-plugin-react-i18next"

interface MenuWrapperProps {
  isMenuOpen: boolean
}
type Props = {
  isMenuOpen: boolean
  handleMenuClick: () => void
}

const Menu: React.FC<Props> = ({ isMenuOpen, handleMenuClick }) => {
  const { t } = useTranslation("", { keyPrefix: "header" })

  return (
    <MenuWrapper isMenuOpen={isMenuOpen}>
      <CloseButton
        variant="outline-white"
        type="button"
        aria-label="Close"
        onClick={handleMenuClick}
      >
        <CloseIcon aria-hidden="true" />
      </CloseButton>
      <Nav>
        <NavLink href={t("navLinks.products.link")} onClick={handleMenuClick}>
          {t("navLinks.products.label")}
        </NavLink>
        <NavLink href={t("navLinks.recipes.link")} onClick={handleMenuClick}>
          {t("navLinks.recipes.label")}
        </NavLink>
        <NavLink href={t("navLinks.ourStory.link")} onClick={handleMenuClick}>
          {t("navLinks.ourStory.label")}
        </NavLink>
        <NavLink href={t("navLinks.cassava.link")} onClick={handleMenuClick}>
          {t("navLinks.cassava.label")}
        </NavLink>
        <NavLink href={t("navLinks.contactUs.link")} onClick={handleMenuClick}>
          {t("navLinks.contactUs.label")}
        </NavLink>
      </Nav>
    </MenuWrapper>
  )
}

export default Menu

const MenuWrapper = styled.div<MenuWrapperProps>`
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* mobile */
  width: auto;
  height: 100vh;
  background-color: var(--primary);
  padding: 4rem 3rem;
  padding: 5.4vh 4vh;
  color: #ffffff;
  pointer-events: ${props => (props.isMenuOpen ? `auto` : `none`)};
  transform: ${({ isMenuOpen }) =>
    isMenuOpen ? `translateX(0)` : `translateX(-100%)`};
  transition: all 0.2s ease-in;

  @media (max-width: 480px) {
    width: 100vw;
    padding: 4rem 3rem;
  }
`

const CloseButton = styled(Button)`
  position: relative;
  min-width: 0;
  padding: 0;
  border: none;
  margin-right: auto;
  margin-bottom: 4rem;
  margin-bottom: 5.4vh;

  &:hover {
    background: none;
  }

  svg {
    display: block;
  }
`

const NavLink = styled.a`
  ${headingsFont}
  display: flex;
  align-items: center;
  font-size: 5rem;
  font-size: 7vh;
  font-weight: 600;
  line-height: 1;
  color: white;
  text-decoration: none;

  &:before {
    counter-increment: nav-counter;
    content: counter(nav-counter, decimal-leading-zero);
    display: block;
    color: var(--secondary-light);
    font-size: 0.75rem;
    letter-spacing: 3px;
    transform: rotate(-90deg);
    transform-origin: center center;
  }
`

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  counter-reset: nav-counter;

  ${NavLink} {
    margin-bottom: 4rem;
    margin-bottom: 5.4vh;
  }
`
